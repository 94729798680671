import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useSelector } from "react-redux";
import { Plus } from "../common/icons";
import { getRequest, updateRequest } from "../api";
import { OrderStatus } from "../constants/OrderStatus";
import { isAllProducts } from "../utils";
import MenuButton from "../components/Button/Menu";
import { ORDERS_DETAILS, POS_CREATE_ORDER } from "../constants/FrontendRoutes";
import MoveOrderModal from "../components/OrderOverview/MoveOrderModal";
import TrackNumberModal from "../components/OrderOverview/TrackNumberModal";
import LabelModal from "../components/Order/LabelModal";
import { selectCurrentUser } from "../redux/authSlice";
import { ORDERS, STORES } from "../constants/BackendRoutes";
import { useDispatch } from "react-redux";
import { addToast } from "../redux/toastSlice";
import { OrderOverviewTable } from "../components/OrderOverview/OrderOverviewTable";
import { OrderOverviewHeader } from "../components/OrderOverview/OrderOverviewHeader";
import SearchInput from "../components/Form/Field/SearchInput";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import stringComparison from "string-comparison";
import TagIcon from "@mui/icons-material/Tag";

const SearchResult = ({ title = "Customers", data = [], query = "" }) => {
  const highlightMatches = (name, query) => {
    if (!query) return name;

    const nameLower = name.toLowerCase();
    const queryLower = query.toLowerCase();

    let longestMatchStart = -1;
    let longestMatchLength = 0;

    for (let i = 0; i < nameLower.length; i++) {
      let currentLength = 0;
      while (
        i + currentLength < nameLower.length &&
        currentLength < queryLower.length &&
        nameLower[i + currentLength] === queryLower[currentLength]
      ) {
        currentLength++;
      }

      if (currentLength > longestMatchLength) {
        longestMatchLength = currentLength;
        longestMatchStart = i;
      }
    }

    if (longestMatchLength > 0) {
      return (
        <>
          {name.slice(0, longestMatchStart)}

          <span className="font-extrabold text-[#4C8C4A]">
            {name.slice(
              longestMatchStart,
              longestMatchStart + longestMatchLength
            )}
          </span>

          {highlightRemainingMatches(
            name.slice(longestMatchStart + longestMatchLength),
            query.slice(longestMatchLength)
          )}
        </>
      );
    }

    return highlightRemainingMatches(name, query);
  };

  const highlightRemainingMatches = (name, query) => {
    if (!query) return name;

    const queryLower = query?.toLowerCase();
    let queryIndex = 0;

    return name?.split("").map((char, index) => {
      if (
        queryIndex < query.length &&
        char.toLowerCase() === queryLower[queryIndex]
      ) {
        queryIndex++;
        return (
          <span key={index} className="font-extrabold text-[#4C8C4A]">
            {char}
          </span>
        );
      }
      return <span key={index}>{char}</span>;
    });
  };

  return (
    <Box
      className="w-full h-full"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 2,
        gap: 1,
        bgcolor: "background.paper",
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <Typography
          variant="body2"
          color="text.primary"
          sx={{
            fontWeight: "fontWeightRegular",
            fontSize: "body2.fontSize",
            letterSpacing: "body2.letterSpacing",
            lineHeight: "body2.lineHeight",
          }}
        >
          {title}
        </Typography>
      </Box>

      {data.map(({ name, onClick }, index) => (
        <Box
          onClick={onClick}
          className="hover:bg-[rgba(243,240,232,0.50)] cursor-pointer"
          key={index}
          sx={{
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              alignItems: "center",
              gap: 2,
              font: "Montserrat",
            }}
          >
            {title === "Customers" ? (
              <GroupOutlinedIcon className="opacity-50" />
            ) : (
              <TagIcon className="opacity-50" />
            )}
            <Box
              sx={{
                display: "inline-flex",
                alignItems: "center",
                fontFamily: "Montserrat",
              }}
            >
              <Typography
                variant="body1"
                component="span"
                sx={{
                  fontFamily: "Montserrat",
                }}
              >
                {highlightMatches(name, query)}
              </Typography>
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

function OrderOverview() {
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  const [quoteOrders, setQuoteOrders] = useState([]);
  const [newOrders, setNewOrders] = useState([]);
  const [shipping, setShipping] = useState([]);
  const [inProgress, setInProgress] = useState([]);
  const [delayed, setDelayed] = useState([]);
  const [repaired, setRepaired] = useState([]);
  const [delivered, setDelivered] = useState([]);
  const [isMoveOrderModalOpen, setIsMoveOrderModalOpen] = useState(false);
  const [trackNumberModal, setTrackNumberModal] = useState(false);
  const [focus, setFocus] = useState(false);
  const [destinationName, setDestinationName] = useState("");
  const [isLabelModalOpen, setIsLabelModalOpen] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [user, setUser] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [allOrders, setAllOrders] = useState([]);
  const [nameResults, setNameResults] = useState([]);
  const [idResults, setIdResults] = useState([]);
  const [dragOrder, setDragOrder] = useState();
  const [movedCard, setMovedCard] = useState({
    startStatus: "",
    endStatus: "",
    source: {},
    destination: {},
  });

  const divRef = useRef(null);

  const navigate = useNavigate();

  const {
    NEW_ORDER,
    SHIPPING,
    IN_PROGRESS,
    DELAYED,
    REPAIRED,
    DELIVERED,
    QUOTE,
  } = OrderStatus;

  const fetchRepairOrders = async () => {
    try {
      if (currentUser) {
        const data = await getRequest(
          `${STORES}/${currentUser?.stores[0].id}${ORDERS}`,
          {},
          "user.addresses,order_line_items,shipping_labels"
        );
        if (data?.length > 0) {
          setAllOrders(data);
          setUser(data[0]?.user);
        }
      }
    } catch (err) {
      dispatch(addToast(err.message || "Something went Wrong!"));
      console.error(err);
    }
  };

  useEffect(() => {
    if (searchValue === "") {
      setIdResults([]);
      setNameResults([]);
      setQuoteOrders(allOrders?.filter((order) => order.status === QUOTE));
      setNewOrders(allOrders?.filter((order) => order.status === NEW_ORDER));
      setShipping(allOrders?.filter((order) => order.status === SHIPPING));
      setInProgress(allOrders?.filter((order) => order.status === IN_PROGRESS));
      setDelayed(allOrders?.filter((order) => order.status === DELAYED));
      setRepaired(allOrders?.filter((order) => order.status === REPAIRED));
      setDelivered(allOrders?.filter((order) => order.status === DELIVERED));
    } else {
      const lcs = stringComparison.lcs;
      const sortedMatches = lcs.sortMatch(searchValue, [
        ...new Set(allOrders.map((order) => order?.user?.name)),
      ]);

      const sortedIds = lcs.sortMatch(
        searchValue,
        allOrders.map((order) => order?.id?.toString())
      );

      const resultsId = sortedIds
        .reverse()
        .slice(0, 10)
        .filter((item) => item.rating >= 0.65)
        .map((item) => item.member);

      setIdResults(resultsId);

      const results = sortedMatches
        .reverse()
        .slice(0, 10)
        .filter((item) => item.rating >= 0.5)
        .map((item) => item.member);

      setNameResults(results);

      const exactResults = sortedMatches
        .reverse()
        .slice(0, 10)
        .filter((item) => item.rating === 1)
        .map((item) => item.member);

      const filteredList = allOrders.filter(
        (item) =>
          exactResults.includes(item?.user?.name) ||
          resultsId.includes(item?.id?.toString())
      );

      setQuoteOrders(filteredList?.filter((order) => order.status === QUOTE));
      setNewOrders(filteredList?.filter((order) => order.status === NEW_ORDER));
      setShipping(filteredList?.filter((order) => order.status === SHIPPING));
      setInProgress(
        filteredList?.filter((order) => order.status === IN_PROGRESS)
      );
      setDelayed(filteredList?.filter((order) => order.status === DELAYED));
      setRepaired(filteredList?.filter((order) => order.status === REPAIRED));
      setDelivered(filteredList?.filter((order) => order.status === DELIVERED));
    }
  }, [allOrders, searchValue]);

  const orderLists = [
    { orders: newOrders, type: NEW_ORDER },
    { orders: shipping, type: SHIPPING },
    { orders: inProgress, type: IN_PROGRESS },
    { orders: delayed, type: DELAYED },
    { orders: repaired, type: REPAIRED },
    { orders: delivered, type: DELIVERED },
    { orders: quoteOrders, type: QUOTE },
  ];

  const sortOrdersByDate = (orders, dateField, orderCategory) => {
    let sortedOrders = [...orders];
    sortedOrders.sort((a, b) => {
      if (a[dateField] === null) return 1;
      if (b[dateField] === null) return -1;
      return new Date(a[dateField]) - new Date(b[dateField]);
    });
    setList(orderCategory, sortedOrders);
  };

  const sortAllOrdersByDate = (dateField) => {
    orderLists.forEach((orderList) =>
      sortOrdersByDate(orderList.orders, dateField, orderList.type)
    );
  };

  const sortAllOrdersByPriority = () => {
    orderLists.forEach((orderList) =>
      sortOrdersByRushFee(orderList.orders, orderList.type)
    );
  };

  const sortOrdersByRushFee = (orders, orderCategory) => {
    let sortedOrders = [...orders];
    sortedOrders.sort((a, b) => {
      if (a.rush_fee > 0 && b.rush_fee <= 0) {
        return -1;
      } else if (a.rush_fee <= 0 && b.rush_fee > 0) {
        return 1;
      } else {
        return 0;
      }
    });
    setList(orderCategory, sortedOrders);
  };

  const handleSortingChange = (sortingCriteria) => {
    if (sortingCriteria === "Completion Date") {
      sortAllOrdersByDate("estimated_completion");
    } else if (sortingCriteria === "Order Date") {
      sortAllOrdersByDate("created_at");
    } else if (sortingCriteria === "Priority") {
      sortAllOrdersByPriority();
    }
  };

  const handleIsMoveOrderModal = () => {
    setDestinationName("");
    setIsMoveOrderModalOpen(false);
  };

  const checkCanCardMove = (card, destination) => {
    const notify = (message) => {
      dispatch(addToast(message));
      return false;
    };

    if (card?.placement_type === "in_store" && destination === "shipping") {
      return notify("We cannot move in-store orders to shipping");
    }

    if (
      isAllProducts(card) &&
      card.status !== destination &&
      destination !== "delivered"
    ) {
      return notify(
        "Product-only orders can only be moved to the delivered status"
      );
    }
    if (
      card?.placement_type === "online" &&
      ["shipping", "repaired"].includes(destination)
    ) {
      const { shipment_type, shipping_labels } = card;
      if (!["two_leg", "three_leg"].includes(shipment_type)) {
        return notify(
          `Before moving to ${destination}, you need to create shipping labels`
        );
      }

      const shippingLegs = new Set(
        shipping_labels.map((label) => label.shipping_leg)
      );

      if (
        shipment_type === "two_leg" &&
        ((destination === "shipping" && !shippingLegs.has("leg_2")) ||
          (destination === "repaired" && !shippingLegs.has("leg_3")))
      ) {
        return notify(
          `Before moving to ${destination}, shipping_leg '${
            destination === "shipping" ? "leg_2" : "leg_3"
          }' must exist`
        );
      }

      if (
        shipment_type === "three_leg" &&
        ((destination === "shipping" && !shippingLegs.has("leg_1")) ||
          (destination === "repaired" && !shippingLegs.has("leg_3")))
      ) {
        return notify(
          `Before moving to ${destination}, shipping_leg '${
            destination === "shipping" ? "leg_1" : "leg_3"
          }' must exist`
        );
      }
    }

    return true;
  };

  const onCancelDrag = () => {
    const result = move(
      getList(movedCard.endStatus),
      getList(movedCard.startStatus),
      movedCard.destination,
      movedCard.source
    );
    setList(movedCard.endStatus, result[movedCard.endStatus]);
    setList(movedCard.startStatus, result[movedCard.startStatus]);
    setIsMoveOrderModalOpen(false);
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) return;

    const startStatus = source.droppableId;
    const endStatus = destination.droppableId;

    if (endStatus === "quote") return;

    const draggedOrder = getList(startStatus).find(
      (order) => order.id.toString() === draggableId
    );
    setDragOrder(draggedOrder);

    if (!checkCanCardMove(draggedOrder, endStatus)) return;

    if (startStatus === endStatus) {
      const items = reorder(
        getList(startStatus),
        source.index,
        destination.index
      );
      setList(startStatus, items);
    } else {
      setIsMoveOrderModalOpen(true);
      setDestinationName(endStatus);

      const result = move(
        getList(startStatus),
        getList(endStatus),
        source,
        destination
      );
      setMovedCard({
        startStatus,
        endStatus,
        source,
        destination,
      });
      setList(startStatus, result[startStatus]);
      setList(endStatus, result[endStatus]);
    }
  };

  const handleCardSave = async ({ endStatus, dragOrderId = 0, sendEmail }) => {
    const data = { order: { status: endStatus }, is_send_email: sendEmail };
    if (endStatus === "shipping") {
      data["order"]["shipping_date"] = new Date();
    } else if (endStatus === "in_progress") {
      data["order"]["in_progress_date"] = new Date();
    }
    try {
      await updateRequest(
        `${STORES}/${currentUser?.stores[0]?.id}${ORDERS}/${dragOrderId}`,
        data
      );
    } catch (error) {
      onCancelDrag();
      setTrackNumberModal(false);
      dispatch(addToast(error));
    }
  };

  const getList = (status) => {
    switch (status) {
      case NEW_ORDER:
        return newOrders;
      case SHIPPING:
        return shipping;
      case IN_PROGRESS:
        return inProgress;
      case DELAYED:
        return delayed;
      case REPAIRED:
        return repaired;
      case DELIVERED:
        return delivered;
      default:
        return [];
    }
  };

  const setList = (status, items) => {
    switch (status) {
      case NEW_ORDER:
        setNewOrders(items);
        break;
      case SHIPPING:
        setShipping(items);
        break;
      case IN_PROGRESS:
        setInProgress(items);
        break;
      case DELAYED:
        setDelayed(items);
        break;
      case REPAIRED:
        setRepaired(items);
        break;
      case DELIVERED:
        setDelivered(items);
        break;
      case QUOTE:
        setQuoteOrders(items);
      default:
        break;
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const handleNotification = (value) => {
    handleCardSave({
      endStatus: movedCard.endStatus,
      dragOrderId: dragOrder.id,
      sendEmail: value,
    });
  };

  useEffect(() => {
    fetchRepairOrders();
  }, [currentUser]);

  const handleBlur = (e) => {
    if (divRef.current && divRef.current.contains(e.relatedTarget)) {
      return;
    }
    setFocus(false);
  };

  return (
    <div className="bg-[#f8f8f8]">
      <div className="flex flex-col gap-5 max-w-[1440px] mx-auto py-5">
        <div className="flex justify-between items-center px-5">
          <div className="flex gap-2 items-center">
            <div className="flex-col justify-start items-start gap-1 inline-flex">
              <div className="text-zinc-900 text-2xl font-bold font-['Montserrat'] leading-[33.60px]">
                Order Overview
              </div>
            </div>
          </div>
          <IconButton>
            <NotificationsNoneIcon sx={{ color: "#939291" }} />
          </IconButton>
        </div>
        <Modal
          open={isMoveOrderModalOpen}
          onClose={() => handleIsMoveOrderModal()}
        >
          <MoveOrderModal
            handleNotification={(value) => handleNotification(value)}
            handleClose={() => handleIsMoveOrderModal()}
            openTrackNumberModal={() => setTrackNumberModal(true)}
            handleBack={onCancelDrag}
            order={dragOrder}
            destinationName={destinationName}
          />
        </Modal>
        <Modal
          open={trackNumberModal}
          onClose={() => setTrackNumberModal(false)}
        >
          <TrackNumberModal
            handleClose={() => setTrackNumberModal(false)}
            openLabelModal={() => setIsLabelModalOpen(true)}
            orderId={dragOrder?.id}
            fetchRepairOrders={fetchRepairOrders}
          />
        </Modal>
        <Modal
          open={isLabelModalOpen}
          onClose={() => setIsLabelModalOpen(false)}
        >
          <LabelModal
            handleClose={() => setIsLabelModalOpen(false)}
            customer={user}
            isFinalShippingLeg={
              dragOrder?.shipping_labels?.length > 0 &&
              dragOrder?.shipping_labels.some(
                (label) => label.shipping_leg == "leg_2"
              )
            }
            orderId={dragOrder?.id}
          />
        </Modal>
        <div className="flex flex-col gap-5">
          <Divider />
          <div className="px-5">
            <OrderOverviewHeader
              setSelectedStatus={setSelectedStatus}
              selectedStatus={selectedStatus}
              newOrders={newOrders}
              shipping={shipping}
              inProgress={inProgress}
              delayed={delayed}
              repaired={repaired}
              delivered={delivered}
            />
          </div>
          <div className="flex flex-col gap-3 py-4 rounded-lg bg-white">
            <div className="flex gap-3 flex-row w-full px-5">
              <MenuButton
                title="Sort by:"
                variant="outlinedSecondary"
                options={["Order Date", "Priority", "Completion Date"]}
                onChange={(value) => handleSortingChange(value)}
                mobileIcon={true}
              />
              <div
                onFocus={() => setFocus(true)}
                ref={divRef}
                tabIndex={-1}
                onBlur={handleBlur}
                className="relative ms-auto"
              >
                <SearchInput
                  placeholder="Search by order ID, or customer name"
                  isOnClearEnabled={true}
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                  onClear={() => setSearchValue("")}
                  width="370px"
                />
                {focus && (
                  <div className="absolute z-[1] w-full max-h-60 overflow-auto shadow-md">
                    {nameResults.length > 0 && (
                      <SearchResult
                        data={nameResults.map((item) => ({
                          name: item,
                          onClick: () => setSearchValue(item),
                        }))}
                        query={searchValue}
                      />
                    )}
                    {idResults.length > 0 && (
                      <SearchResult
                        title="Order ID"
                        data={idResults.map((item) => ({
                          name: item,
                          onClick: () =>
                            navigate(ORDERS_DETAILS.replace(":id", item)),
                        }))}
                        query={searchValue}
                      />
                    )}
                  </div>
                )}
              </div>
              <Button
                className="text-nowrap h-[40px] flex-shrink-0"
                variant="containedPrimary"
                onClick={() => navigate(POS_CREATE_ORDER)}
                startIcon={<Plus />}
              >
                Add new order
              </Button>
            </div>
            <OrderOverviewTable
              onDragEnd={onDragEnd}
              selectedStatus={selectedStatus}
              quoteOrders={quoteOrders}
              newOrders={newOrders}
              shipping={shipping}
              inProgress={inProgress}
              delayed={delayed}
              repaired={repaired}
              delivered={delivered}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderOverview;
